import axios from '@/util/api.request.js'

export const login = (username, password) => {
  return axios.request({
    url: 'admin/signIn',
    params: {
      username,
      password
    },
    method: 'post'
  })
}

export const updateAdmin = (params) => {
  return axios.request({
    // url: `admin/update?${$json.stringify(values)}`,
    url: `admin/update`,
    method: 'post',
    params
  })
}
