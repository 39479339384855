import axios from 'axios'
import { message } from 'antd';

class HttpRequest {
  constructor (baseUrl) {
    this.baseUrl = baseUrl
    this.queue = {}
  }
  getInsideConfig () {
    const token = localStorage.getItem('flerken-token')
    const config = {
      baseURL: this.baseUrl,
      headers: {
        Pragma: 'no-cache',
        'Cache-control': 'no-cache',
        token
      }
    }
    return config
  }
  destroy (url) {
    delete this.queue[url]
  }
  interceptors (instance, url) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      // instance.defaults.headers['Cookie'] =  config.headers['set-cookie'][0]
      this.queue[url] = true
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      this.destroy(url)
      const { data } = res
      if (res.data.code === -1) {
        localStorage.clear()
        window.location.replace('/')
        message.error(res.data.msg)
      } else if (res.data.code === 1) {
        message.error(res.data.msg)
      } else if (res.data.code === 7) {
        // localStorage.clear()
        // window.location.replace('/')
      }
      return Promise.resolve(data)
    }, error => {
      this.destroy(url)
      let errorInfo = error.response
      let _msg
      if (!errorInfo) {
        const { request: { statusText, status }, config } = JSON.parse(JSON.stringify(error))
        errorInfo = {
          statusText,
          status,
          request: { responseURL: config.url }
        }
        _msg = status + '：' + errorInfo.statusText
      } else {
        if (error.response.data.code === -1) {
          localStorage.clear()
          window.location.replace('/')
          _msg = error.response.data.msg
        } else if (errorInfo.data.code === 1) {
          _msg = errorInfo.data.msg
        } else {
          _msg = error.response.data.status + '：' + error.response.data.message
        }
      }
      message.error(_msg)
      return Promise.reject(_msg)
    })
  }
  request (options) {
    const instance = axios.create({
      timeout: 20000,
      withCredentials: true
    })
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance, options.url)
    return instance(options)
  }
}
export default HttpRequest
