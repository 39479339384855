import Promise from 'promise-polyfill';
import 'fetch-detector';
import 'fetch-ie8';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import {persistStore} from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react'
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import store from './Store.js';
import App from './App';
import { replaceHttp } from '@/util/publicPath.js'
import '@babel/polyfill';
import 'antd/dist/antd.css';
import '@/assets/css/common.css';
import '@/assets/css/pagination.css'
import '@/assets/css/form.scss'
require('es6-promise').polyfill();
window.Promise = Promise;
moment.locale('zh-cn');

if (replaceHttp && process.env.NODE_ENV === 'production' && window.location.protocol === 'http:') {
  window.location.replace(window.location.href.replace('http', 'https'))
}

let browser = {
  versions: function () {
    let u = navigator.userAgent
    let ua = navigator.userAgent.toLocaleLowerCase()
    return {
      userAgent: u,
      trident: u.indexOf('Trident') > -1,
      /*IE内核*/
      presto: u.indexOf('Presto') > -1,
      /*opera内核*/
      webKit: u.indexOf('AppleWebKit') > -1,
      /*苹果、谷歌内核*/
      gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1,
      /*火狐内核*/
      mobile: !!u.match(/AppleWebKit.*Mobile.*/),
      /*是否为移动终端*/
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      /*ios终端*/
      android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
      /*android终端或者uc浏览器*/
      iPhone: u.indexOf('iPhone') > -1,
      /*是否为iPhone或者QQHD浏览器*/
      iPad: u.indexOf('iPad') > -1,
      /*是否iPad*/
      webApp: u.indexOf('Safari') === -1,
      /*是否web应该程序，没有头部与底部*/
      souyue: u.indexOf('souyue') > -1,
      superapp: u.indexOf('superapp') > -1,
      weixin: u.toLowerCase().indexOf('micromessenger') > -1,
      qq: ua.match(/QQ/i) === "qq", // QQ
      weiBo: ua.match(/WeiBo/i) === "weibo", // 微博
      Safari: u.indexOf('Safari') > -1,
      QQbrw: u.indexOf('MQQBrowser') > -1, // QQ浏览器
      webview: !(u.match(/Chrome\/([\d.]+)/) || u.match(/CriOS\/([\d.]+)/)) && u.match(/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/),
      ucweb: function () {
        try {
          return parseFloat(u.match(/ucweb\d+\.\d+/gi).toString().match(/\d+\.\d+/).toString()) >= 8.2
        } catch (e) {
          if (u.indexOf('UC') > -1) {
            return true;
          }
          return false;
        }
      }(),
      Symbian: u.indexOf('Symbian') > -1,
      ucSB: u.indexOf('Firofox/1.') > -1
    }
  }(),
  language: (navigator.browserLanguage || navigator.language).toLowerCase()
}
browser.test = 1
// console.log(browser.versions)

let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
      <PersistGate persistor={persistor}>
          <HashRouter>
          <ConfigProvider locale={zhCN}>
            <App />
          </ConfigProvider>
          </HashRouter>
      </PersistGate>
    </Provider>
  ,
  document.getElementById('root')
);