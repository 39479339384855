// import { actions as loadingActions } from '@/components/loading/index';
import { saveUserInfo } from '@/actions/user'
import * as LocalStorage from '@/util/localstorage';
import { login as loginIn } from '@/api/user.js'

export const login = (formVal, history) => {
  return (dispatch) => {
    // dispatch(loadingActions.showLoading());

    loginIn(formVal.username, formVal.password).then((res) => {
      // dispatch(loadingActions.hideLoading());
      if (res.code === 0) {
        dispatch(saveUserInfo(res.data))
        LocalStorage.put('username', formVal.username);
        // LocalStorage.put('flerken-token', res.data.token);
        localStorage.setItem('flerken-token', res.data.token);
        // 没有发生异常，跳转至主页
        history.push('/home/order-manage');
      }
    }).catch(err => {
      // dispatch(loadingActions.hideLoading());
      console.log(err, 'err')
    })
  };
};
// import { actions as loadingActions } from '@/components/loading/index';
// import { saveUserInfo } from '@/actions/user'
// import * as Fetch from '@/util/fetch';
// import * as LocalStorage from '@/util/localstorage';

// export const login = (formVal, history) => {
//   return (dispatch) => {
//     dispatch(loadingActions.showLoading());

//     Fetch.post(`admin/signIn?username=${formVal.username}&password=${formVal.password}`).then((res) => {
//       dispatch(loadingActions.hideLoading());
//       if (res.code === 0) {
//         dispatch(saveUserInfo(res.data))
//         LocalStorage.put('username', formVal.username);
//         // 没有发生异常，跳转至主页
//         history.push('/home/order-manage');
//       }
//     });
//   };
// };