import React, { Component } from 'react';
import { Dropdown, Icon, Menu } from 'antd';
import { withRouter } from 'react-router-dom'
import store from '@/Store.js';
import { oss } from '@/util/publicPath.js'
import cookie from 'react-cookies'
import { connect } from 'react-redux';
import styles from './header.module.css';

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: store.getState().user
    }

    store.subscribe(() => {
      this.setState({
        user: store.getState().user
      })
    })
  }

  UNSAFE_componentWillMount(){
    if (!this.state.user.id) {
      this.props.history.push('/')
    }
  }

  UNSAFE_componentWillUpdate() {
    if (!this.state.user.id) {
      this.props.history.push('/')
    }
  }

  outLogin = () => {
    localStorage.clear()
    cookie.remove('FLERKEN_SID')
    window.location.replace('/')
  }

  menu = () => (
    <Menu>
      <Menu.Item key="2" style={{minWidth: 120}} onClick={this.outLogin.bind()}>
        <Icon type="poweroff" />&nbsp;退出登录
      </Menu.Item>
    </Menu>
  );


  render () {
    return (
      <div className={styles['header-wrapper']}>
        <span className={styles['header-collapsed']} onClick={() => this.props.setCollapsed(!this.props.collapsed)}>
          <Icon type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'} />
        </span>
        <div className={styles['header-user-info']}>
          <Dropdown overlay={this.menu()} placement="bottomRight" style={{left: 'auto', top: 65}}>
            <span className={styles['header-dropdown-link']}>
            { this.state.user.username } 
            {
              this.state.user.avatar ? <img className={styles.avatar} src={oss + this.state.user.avatar} alt=""/> : (<Icon type="user" />)
            }
            </span>
          </Dropdown>
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(Header));