import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { view as Loading } from '@/components/loading';
import { view as Login } from '@/pages/login';
import { view as Home } from '@/pages';

class App extends Component {

  constructor(props){
    super(props)
    this.state = {}
  }

  render () {
    return  (
      <>
        <Loading />
        <Route path="/" component={Login} exact />
        <Route path="/login" component={Login} />
        <Route path="/home" component={Home} />
      </>
    );
  } 
};

export default App;