import user from './user'
import app from './app'
import { reducer as loadingReducer } from '../components/loading';
import { routerReducer } from 'react-router-redux';

const originalReducers = {
  loading: loadingReducer,
  routing: routerReducer,
  user,
  app
};

export default originalReducers