import React, { Component } from 'react';
import { Form, Input } from 'antd';
import { connect } from 'react-redux';
import { actions as loginActions } from './index';
import logo from '@/assets/images/logo.png';
import styles from './login.module.scss'
import store from '@/Store.js';
const FormItem = Form.Item;

class LoginPage extends Component {
  constructor (props) {
    super(props)

    this.state = { }
  }

  UNSAFE_componentWillMount () {
    let { user } = store.getState()
    if (user.id) { // 有user 则已登录
      this.props.history.push('/home/order-manage')
    }
  }
  
  gotoLogin = (e) => {
    e.preventDefault()
		this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.login(values);
      }
    })
  };
  
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className={styles.login}>
        <div className={styles.header}>
          <div className={styles['header-wrapper']}>
            <header>
              <a href="/">
                <img src={logo} alt="" />
                <h2>供应商后台管理系统</h2>
              </a>
            </header>
          </div>
        </div>
        <div className={styles["login-title"]}>登录</div>
        <div className={styles.content}>
          <Form onSubmit={this.gotoLogin} className={styles['login-form']}>
            <span className={styles['input-tips']}>请输入用户名</span>
            <FormItem hasFeedback>
            {
                getFieldDecorator('username', {
                  rules: [
                    {
                      required: true,
                      message: '请输入用户名!',
                    }
                  ],
                })(<Input/>)
              }
            </FormItem>
            <span className={styles['input-tips']}>请输入密码</span>
            <FormItem hasFeedback>
              {
                getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: '请输入密码!',
                    }
                  ],
                })(<Input.Password/>)
              }
            </FormItem>
            <FormItem >
              <button type="submit" className={styles['login-form-button']}>立即登录</button>
            </FormItem>
          </Form>
        </div>
        <div className={styles['footer']}> <a href="http://beian.miit.gov.cn" target="blank">沪ICP备16003223号-1 添晴科技 2019-2029</a> </div>
      </div>
    );
  }
  
}

const mapDispachToProps  = (dispatch, props) => ({
  login: (formValue) => {
    // 等待
    dispatch(loginActions.login(formValue, props.history));
  }
});

export default connect(null, mapDispachToProps)(Form.create({ name: 'login' })(LoginPage))