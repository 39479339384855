import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import { view as Header } from '../components/header';
import { Sidebar } from '../components/sidebar';
import { view as OrderList } from './order-manage/order-list';
import { view as OrderDetail } from './order-manage/order-detail';
import { view as SetUser } from './set-user';
import { view as Logistics } from './logistics';
import { view as Home } from './home';
import styles from './home.module.scss';

const HomePage = () => {
  const [collapsed, setCollapsed] = useState(false);
  const sidebarWidth = collapsed ? 80 : 200;
  const sidebarStyle = {
    flex: '0 0 ' + sidebarWidth + 'px',
    width: sidebarWidth + 'px'
  };

  return (
    <div className="ant-layout ant-layout-has-sider">
      <div style={sidebarStyle} className="ant-layout-sider ant-layout-sider-dark">
        <Sidebar collapsed={collapsed} />
      </div>
      <div className={`${styles['content-wrapper']} ant-layout`}>
        <div className={`${styles.header} ant-layout-header`}>
          <Header collapsed={collapsed} setCollapsed={setCollapsed} />
        </div>
        <div className={styles['main-content']}>
          <div className={`${styles.content}`}>
            <Route path="/home/home" component={Home} />
            <Route path="/home/order-manage" component={OrderList} />
            <Route path="/home/order-detail/:id" component={OrderDetail} />
            <Route path="/home/set-user" component={SetUser} />
            <Route path="/home/logistics" component={Logistics} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;